import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery, navigate } from "gatsby"
import { connect } from "react-redux"
import * as actions from "@redux/actions"

import Layout from "@components/Layout"
import SEO from "@components/Seo"
import { Button, Error } from "@components/Form"
import Loader from "@components/Loader"
import { H1, HR } from "@components/StyledElements"

import { SERVER_URL } from "@config"
import { GetToken } from "@utils"

const ProfilePaymentPage = ({ authenticated, ReduxLogout }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const data = useStaticQuery(graphql`
    query ProfilePaymentQuery {
      banner: allFile(filter: { name: { eq: "client_portal_1440x400" } }) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1440) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            id
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (!authenticated) {
      ReduxLogout()
    }
  }, [authenticated])

  const banner = data.banner.edges[0].node.childImageSharp.fluid
  return (
    <Layout fluid={banner} alt={"Neil Hill Coaching"} height={300}>
      <div className="container-fluid">
        <SEO title="Client Profile | Payment Settings" />
        <div className="row d-flex justify-content-center mt-5">
          <div className="col-12 mt-2 text-center">
            <H1>Payment Settings</H1>
          </div>
          {loading && (
            <div className="col-12 mt-2 text-center">
              <Loader message={"Loading payment information 💪"} />
            </div>
          )}
          {error && (
            <div className="col-12 my-3 text-center">
              <Error message={error} />
            </div>
          )}
        </div>
        <div className="row d-flex justify-content-center mt-2">
          <div className="col-md-8 mx-auto">
            <HR />
          </div>
          <div className="col-12 mt-2 text-center">
            <Button onClick={() => navigate("/app/profile")}>Go Back</Button>
          </div>
        </div>
      </div>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    authenticated: state.auth.authenticated,
    loading: state.auth.loading,
  }
}
export default connect(mapStateToProps, actions)(ProfilePaymentPage)
